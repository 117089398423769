import { SlatePlugin } from '../../slatePlugins.types';
import { renderLeafAbbrReadOnly } from './renderLeafAbbr';
import { AbbrPluginOptions } from './types';

/**
 * Enables support for cite formatting
 */
export const AbbrReadOnlyPlugin = (options?: AbbrPluginOptions): SlatePlugin => ({
  renderLeaf: renderLeafAbbrReadOnly(options),
});
