import * as React from 'react';
import { isElementNode, isTextNode } from './utils';

export interface RendererContext {
  plugins: any[];
  renderElementList: ((node) => any)[];
  renderLeafList: ((node) => any)[];
}

export const NodeRenderer = ({ node, renderContext }) => {
  if (isElementNode(node)) {
    return <ElementRenderer node={node} renderContext={renderContext} />;
  }
  if (isTextNode(node)) {
    return <LeafRenderer node={node} renderContext={renderContext} />;
  }
};

const LeafRenderer = ({ node, renderContext }) => {
  if (isTextNode(node)) {
    const { renderLeafList, plugins } = renderContext;
    let element = renderLeafList.reduce((value, renderLeafFn) => {
      if (renderLeafFn) {
        return renderLeafFn({ leaf: node, children: value });
      }
      return value;
    }, node.text);

    element = plugins.reduce((value, plugin) => {
      const { renderLeaf: renderLeafFn } = plugin;
      if (renderLeafFn) {
        return renderLeafFn?.({
          leaf: node,
          children: value,
        });
      }
      return value;
    }, element || node.text);

    if (element) {
      // console.info('Leaf', element, node);
      return element;
    }

    return node.text;
  }
};

function handleRenderElementFn(
  renderElementFn: (node) => any,
  node,
  renderContext: RendererContext
) {
  if (renderElementFn) {
    let children = null;
    if (node.children) {
      children = node.children.map((child, index) => (
        <NodeRenderer key={index} node={child} renderContext={renderContext} />
      ));
    }
    return renderElementFn({ attributes: {}, element: node, children });
  }
}

const ElementRenderer = ({ node, renderContext }) => {
  if (isElementNode(node)) {
    const { renderElementList, plugins } = renderContext;
    let element = null;
    renderElementList.some(
      (renderElementFn) => (element = handleRenderElementFn(renderElementFn, node, renderContext))
    );

    if (!element) {
      plugins.some(
        ({ renderElement: renderElementFn }) =>
          (element = handleRenderElementFn(renderElementFn, node, renderContext))
      );
    }

    if (!element) {
      // Fallback to default renderer
      element = handleRenderElementFn(({ children }) => children, node, renderContext);
    }

    return element;
  }
};
