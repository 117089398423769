import { getRenderLeafDefault } from '../../common/utils/getRenderLeafDefault';
import { DEFAULTS_ABBR, DEFAULTS_ABBR_READ_ONLY } from './defaults';
import { AbbrRenderLeafOptions } from './types';

export const renderLeafAbbr = (options?: AbbrRenderLeafOptions) =>
  getRenderLeafDefault({
    key: 'abbr',
    defaultOptions: DEFAULTS_ABBR,
    options,
  });

export const renderLeafAbbrReadOnly = (options?: AbbrRenderLeafOptions) =>
  getRenderLeafDefault({
    key: 'abbr',
    defaultOptions: DEFAULTS_ABBR_READ_ONLY,
    options,
  });

