import { getRenderLeafDefault } from '../../common/utils/getRenderLeafDefault';
import { DEFAULTS_CITE } from './defaults';
import { CiteRenderLeafOptions } from './types';

export const renderLeafCite = (options?: CiteRenderLeafOptions) =>
  getRenderLeafDefault({
    key: 'cite',
    defaultOptions: DEFAULTS_CITE,
    options,
  });
