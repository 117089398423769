import * as React from 'react';
import { RenderElementProps } from 'slate-react';

const hasOnlyEmptyTextNode = (element) => {
  if (element.children.length === 1) {
    const nodeText = element.children[0] as any;
    if (nodeText.text === '') {
      return true;
    }
  }

  return false;
};

export const ParagraphPlugin = {
  renderElement: (renderElementProps: RenderElementProps) => {
    const { element, children, attributes } = renderElementProps;
    if (element.type === 'p') {
      if (element.children.length === 0) {
        return <p {...attributes}>&nbsp;</p>;
      }
      if (hasOnlyEmptyTextNode(element)) {
        return <p {...attributes}>&nbsp;</p>;
      }
      return <p {...attributes}>{children}</p>;
    }
  },
};
/*
return element.children?.map(item => {
        if (item?.q) {
          return <p><q {...attributes}>{children}</q></p>;
        }
        if (item?.abbr) {
          return <p><abbr {...attributes} title={item?.abbr_title}>{children}</abbr></p>;
        }
        if (item.cite) {
          return <p><cite {...attributes}>{children}</cite></p>;
        }
        return <p {...attributes}>{children}</p>;
      });
 */
