import { SlatePlugin } from '../../slatePlugins.types';
import { renderLeafQ } from './renderLeafQ';
import { QPluginOptions } from './types';

/**
 * Enables support for cite formatting
 */
export const QReadOnlyPlugin = (options?: QPluginOptions): SlatePlugin => ({
  renderLeaf: renderLeafQ(options),
});
