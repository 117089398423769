import * as React from 'react';
import { NodeRenderer, RendererContext } from './renderElement';

export interface RendererWithPluginsProps {
  value: any[];
  plugins?: any[];
  renderElement?: any[];
  renderLeaf?: any[];
}

export const RendererWithPlugins = ({
  plugins = [],
  value,
  renderElement: renderElementList = [],
  renderLeaf: renderLeafList = [],
}: RendererWithPluginsProps) => {
  const rendererPlugins = React.useMemo(
    () => plugins.filter((plugin) => plugin.renderElement || plugin.renderLeaf),
    [plugins]
  );

  const renderContext: RendererContext = React.useMemo(
    () => ({
      renderLeafList,
      plugins: rendererPlugins,
      renderElementList,
    }),
    [renderLeafList, renderElementList, rendererPlugins]
  );

  return value?.map((node, index) => (
    <NodeRenderer key={index} node={node} renderContext={renderContext} />
  )) as any;
};
