/* stylelint-disable value-no-vendor-prefix, property-no-vendor-prefix, no-descending-specificity */
import { IconChevronChevronRight } from '@doltech/icons/IconChevronChevronRight';
import { components } from '@doltech/pageManagement/dto';
import cl from 'classnames';
import * as React from 'react';
import styled, { css } from 'styled-components';
import { Box } from '../../../../figma/Common/BoxV2';
import classes from './DescriptionEditorView.module.less';
import {
  Config,
  DescriptionEditorViewModalProps,
  DolEllipsisConfig,
  LimitContainerConfig,
} from './DescriptionEditorView.types';
import { ResponsiveTypography } from '../../../../figma/Typography/v3/ResponsiveTypography';
import { descriptionEditorViewModal } from './DescriptionEditorViewModal';

import dynamic from 'next/dynamic';
import { useGlobalModalStore } from '../../../../hocs/withGlobalModal';
import { PreviewDescriptionEditor } from './PreviewDescriptionEditor';

const DescriptionEditorViewUI = dynamic<any>(
  () => import('./DescriptionEditorViewUI').then((mod) => mod.DescriptionEditorViewUI),
  {
    ssr: false,
  }
);

const ellipsisStyle = css<DolEllipsisConfig>`
  &.ellipsis {
    .blog-editor-container {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: ${(p) => p.rows || 2};
      overflow: hidden;
      max-height: 56px;
    }
  }
`;

const limitContainerStyle = css<LimitContainerConfig>`
  &.limit-container {
    position: relative;
    max-height: ${(p) => p.maxHeight || 100}px;
    overflow: hidden;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100px;
      background: linear-gradient(
        to top,
        rgba(255, 255, 255, 1),
        rgba(255, 255, 255, 0.5),
        rgba(255, 255, 255, 0)
      );
    }
  }
`;

const Content = styled.div<Config>`
  margin-bottom: 12px;

  .blog-editor-container {
    min-height: 56px;
  }

  &.compact {
    ${ellipsisStyle}
    ${limitContainerStyle}
  }
`;

export interface DescriptionEditorViewProps extends Pick<DescriptionEditorViewModalProps, 'title'> {
  body?: string;
  mediaInfos?: components['schemas']['MediaInfoDTO'][];
  compactConfig?: Config;
  compact?: boolean;
  fontInter?: boolean;
}

const DescriptionEditorViewContent = (props: DescriptionEditorViewProps) => {
  const {
    title,
    body,
    compactConfig = {
      mode: 'ellipsis',
    },
    compact = false,
    fontInter = false,
  } = props;
  const { mode } = compactConfig;
  const { show } = useGlobalModalStore();

  const bodyNode = React.useMemo(() => {
    if (!body) {
      return undefined;
    }

    return JSON.parse(body);
  }, [body]);

  const limitedBodyNode = React.useMemo(() => {
    let convertedBody = [...bodyNode];

    if (mode === 'node') {
      const { limit } = compactConfig;
      if (convertedBody && limit && convertedBody.length > limit) {
        convertedBody = convertedBody.slice(0, limit);
      }
    }

    return convertedBody;
  }, [bodyNode, compactConfig, mode]);

  const renderContent = React.useCallback(() => {
    if (compact) {
      return (
        <>
          <Content className={cl(mode, { compact })} {...compactConfig}>
            <PreviewDescriptionEditor
              initialValue={compact ? limitedBodyNode : (bodyNode as any)}
            />
          </Content>
          <Box
            className={classes.seeMore}
            onClick={async () => {
              show({
                ...descriptionEditorViewModal,
                contentProps: {
                  title,
                  descriptionNode: bodyNode,
                  fontInter,
                },
              });
            }}
          >
            <ResponsiveTypography.Text variant="semi-bold/16" mr="4px">
              Xem thêm
            </ResponsiveTypography.Text>
            <IconChevronChevronRight fontSize={24} />
          </Box>
        </>
      );
    }
    return (
      <Content className={cl(mode, { compact })} {...compactConfig}>
        <DescriptionEditorViewUI initialValue={compact ? limitedBodyNode : (bodyNode as any)} />
      </Content>
    );
  }, [compact, mode, compactConfig, limitedBodyNode, bodyNode, show, title, fontInter]);

  return (
    <div className={cl(classes.descriptionView, { [classes.fontInter]: fontInter })}>
      {renderContent()}
    </div>
  );
};

export const DescriptionEditorView: React.FC<DescriptionEditorViewProps> =
  DescriptionEditorViewContent;
