import { IconOtherClose } from '@doltech/icons/IconOtherClose';
import * as React from 'react';

import { Button } from '../../../../figma/ButtonV2/ButtonV2';
import { Typography } from '../../../../figma/DesignSystemV2/Typography';
import { DescriptionEditorViewModalProps } from './DescriptionEditorView.types';
import dynamic from 'next/dynamic';
import { SpokePageSpinner } from '@doltech/core/lib/auth/SpokePageSpinner';
import { GlobalModalContent, useGlobalModalStore } from '../../../../hocs/withGlobalModal';
import styled from 'styled-components';
import cl from 'classnames';

const DescriptionEditorViewUI = dynamic<any>(
  import('./DescriptionEditorViewUI').then((mod) => mod.DescriptionEditorViewUI),
  {
    ssr: false,
    loading: () => <SpokePageSpinner />,
  },
);

const Main = styled.div<{ $fontInter?: boolean }>`
  padding: 24px;
  background-color: #fff;
  display: grid;
  gap: 32px;
  grid-template-columns: minmax(0, 1fr);
  grid-auto-rows: max-content;
  min-height: 100vh;
  .header {
    display: grid;
    grid-template-columns: minmax(0, 1fr) max-content;
    align-items: center;
    justify-content: space-between;
  }
  &.font-inter {
    font-family: Inter, sans-serif;
    .dol-editor {
      font-family: Inter, sans-serif !important;
    }
  }
`;

export const DescriptionEditorViewModal = (props: DescriptionEditorViewModalProps) => {
  const { title, descriptionNode, fontInter } = props;
  const { hide } = useGlobalModalStore();
  return (
    <Main className={cl({ 'font-inter': fontInter })} $fontInter={fontInter}>
      <div className="header">
        <Typography.Title mt="4" className="font-noto" level={2} variant="semi-bold/32-48">
          {title}
        </Typography.Title>
        <Button.Icon type="secondary" shape="rounded-rect" onClick={hide}>
          <IconOtherClose fontSize={24} />
        </Button.Icon>
      </div>
      <React.Suspense fallback={<SpokePageSpinner />}>

      <DescriptionEditorViewUI initialValue={descriptionNode as any} />
      </React.Suspense>
    </Main>
  );
};

export const descriptionEditorViewModal: GlobalModalContent = {
  content: (props) => <DescriptionEditorViewModal {...props} />,
  title: null,
  closable: false,
  width: 760,
  modalStyle: 'unstyled-full-height',
};
