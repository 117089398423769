import * as React from 'react';
import styled from 'styled-components';
import { fromScreen } from '@doltech/core/lib/responsive/responsive.util';
import cl from 'classnames';
import { colorsV2 } from '../../../../figma/colors-v2';
import { HeadingReadOnlyPlugin } from '../../../../slatePlugins/elements/heading/HeadingReadOnlyPlugin';
import { HyperLinkElementReadOnlyPlugin } from '../../../../figma/DolEditor/elements/hyper-link/HyperLinkElementReadOnlyPlugin';
import { ParagraphPlugin } from '../../../../slatePlugins/components/core/readonly/ParagraphPlugin';
import { ItalicReadOnlyPlugin } from '../../../../slatePlugins/marks/italic/ItalicReadOnlyPlugin';
import { BoldReadOnlyPlugin } from '../../../../slatePlugins/marks/bold/BoldReadOnlyPlugin';
import { UnderlineReadOnlyPlugin } from '../../../../slatePlugins/marks/underline';
import { StrikethroughReadOnlyPlugin } from '../../../../slatePlugins/marks/strikethrough/StrikethroughReadOnlyPlugin';
import { RendererWithPlugins } from '../../../../slatePlugins/components/core/readonly/RendererWithPlugins';
import { AbbrReadOnlyPlugin } from '../../../../slatePlugins/marks/abbr/AbbrReadOnlyPlugin';
import { CiteReadOnlyPlugin } from '../../../../slatePlugins/marks/cite/CiteReadOnlyPlugin';
import { QReadOnlyPlugin } from '../../../../slatePlugins/marks/q/QReadOnlyPlugin';

const Main = styled.div`
  font-family: noto-serif, serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;

  > p {
    margin-top: 24px;
    white-space: pre-wrap;
  }
  p {
    margin-bottom: 0;
  }

  a {
    color: ${colorsV2.blue100};
  }

  ${fromScreen(768)} {
    font-size: 20px;
    line-height: 32px;
  }
`;

const plugins = [
  HeadingReadOnlyPlugin(),
  HyperLinkElementReadOnlyPlugin(),
  ParagraphPlugin,
  ItalicReadOnlyPlugin(),
  BoldReadOnlyPlugin(),
  UnderlineReadOnlyPlugin(),
  StrikethroughReadOnlyPlugin(),
  AbbrReadOnlyPlugin(),
  CiteReadOnlyPlugin(),
  QReadOnlyPlugin(),
];

const PreviewDescriptionEditorBase = ({ initialValue, className = '' }) => {
  return (
    <Main className={cl('blog-editor-container preview-description-editor', className)}>
      <RendererWithPlugins plugins={plugins} value={initialValue} />
    </Main>
  );
};

export const PreviewDescriptionEditor = PreviewDescriptionEditorBase;
