import { SlatePlugin } from '../../slatePlugins.types';
import { renderLeafCite } from './renderLeafCite';
import { CitePluginOptions } from './types';

/**
 * Enables support for cite formatting
 */
export const CiteReadOnlyPlugin = (options?: CitePluginOptions): SlatePlugin => ({
  renderLeaf: renderLeafCite(options),
});
