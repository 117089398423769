import { SlatePlugin } from '../../slatePlugins.types';
import { renderElementHeading } from './renderElementHeading';
import { HeadingPluginOptions } from './types';

/**
 * Enables support for headings with configurable levels
 * (from 1 to 6).
 */
export const HeadingReadOnlyPlugin = (options?: HeadingPluginOptions): SlatePlugin => ({
  renderElement: renderElementHeading(options),
});
