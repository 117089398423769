import { SlatePlugin } from '../../slatePlugins.types';
import { renderLeafItalic } from './renderLeafItalic';
import { ItalicPluginOptions } from './types';

/**
 * Enables support for italic formatting.
 */
export const ItalicReadOnlyPlugin = (options?: ItalicPluginOptions): SlatePlugin => ({
  renderLeaf: renderLeafItalic(options),
});
