import { SlatePlugin } from '../../slatePlugins.types';
import { renderLeafBold } from './renderLeafBold';
import { BoldPluginOptions } from './types';

/**
 * Enables support for bold formatting
 */
export const BoldReadOnlyPlugin = (options?: BoldPluginOptions): SlatePlugin => ({
  renderLeaf: renderLeafBold(options),
});
