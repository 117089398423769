import { getRenderLeafDefault } from '../../common/utils/getRenderLeafDefault';
import { DEFAULTS_Q } from './defaults';
import { QRenderLeafOptions } from './types';

export const renderLeafQ = (options?: QRenderLeafOptions) =>
  getRenderLeafDefault({
    key: 'q',
    defaultOptions: DEFAULTS_Q,
    options,
  });
